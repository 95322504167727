.Dropdown_Container {
    margin-bottom: 30px;
}

.Dropdown_Button > button {
    width: 408px;
    color: #fff;
    text-align: start;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #434a50;
  }

  .Dropdown_Button > .btn-primary:hover,.Dropdown_Button > .dropdown-toggle {
    color: #fff !important;
    background-color: #434a50 !important;
    border-color: #434a50 !important;
    box-shadow: 0 0 0 0.2rem rgb(67 74 80 / 50%) !important;
  }

  .dropdown-menu.show {
    width: 410px;
    max-height: 536px;
    overflow-y: auto;
    color: #fff !important;
    background-color: #434a50;
    margin-top: 4px !important;
  }

  .dropdown-item:hover {
    background-color: #575e62 !important;
  }

  .dropdown-item {
    color: #fff !important;
    height: 48px;
    padding: 0.70rem 1.5rem !important;
    display: flex !important;
    justify-content: space-between;
  }

  .dropdown-item_name {
    color: #fff !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropdown-item_text {
    width: 240px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .dropdown-item_hint {
    color: #fee86c !important;
  }

  .dropdown-item_create {
    width: 408px;
    color: #fff !important;
    height: 48px;
    padding: 0.70rem 1.5rem !important;
    display: flex !important;
    justify-content: space-between;
    background-color: #7a7f82 !important;
    border-color: #919597 !important;
  }

  .dropdown-item_create:hover {
    background-color: #919597 !important;
  }

  .dropdown-item_add {
    background-color: #919597 !important;
  }

  .dropdown-item_add:hover {
    background-color: #7a7f82 !important;
  }

.dropdown-item.disabled {
  color: #7a7f82 !important;
}

.dropdownInput {
  width: 408px;
  color: #fff !important;
  height: 48px;
  padding: 0.70rem 1.5rem !important;
  display: flex !important;
  justify-content: space-between;
  background-color: #434a50 !important;
  /* border-color: #434a50 !important; */
}

.dropdownInput::placeholder {
  color: #919597;
  opacity: 1; /* Firefox */
}
