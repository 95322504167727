.Container_Error {
    background-image: url("../../assets/background_Image.png");
    background-position-x: center;
    background-position-y: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    background-color: #20252b;
}

.Error_wrapper {
    display: flex;
}

.Error_content {
    width: 656;
    position: absolute;
    top: 178px;
    left: 182px;
}

.Error_Logo {
    width: 40px;
}

.Error_Header {
    width: 656px;
    color: #fff;
    font-size: 44px;
    font-weight: 600;
    letter-spacing: 0.3px;
}

.Error_Message {
    width: 368px;
    color: #ffffff;
    margin: 16px 143px 0 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.52;
}

.Send_to_Bluescape_button {
    width: 164px;
    margin-top: 50px;
    height: 48px;
    border-radius: 8px;
}

.Error_icons {
    position: absolute;
    top: 460px;
    right: 50px;
}

.fish_1 {
    position: absolute;
    right: 180px;
}

.fish_2 {
    position: absolute;
    right: 40px;
    top: -50px;
}

.fish_3 {
    position: absolute;
    right: 0;
    top: 100px;
}