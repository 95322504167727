.Progress_Container {
    width: 408px;
    padding: 18px 16px 12px;
    border-radius: 8px;
    height: 270px;
    background-color: #20252b;
    overflow-y: auto;
}

.Progress_Header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 8px 0;
    font-size: 0.875rem;
    max-height: 21px;
}
.progress {
    height: 4px;
    width: 324px;
}

.Progress_Bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Progress_Wapper {
    margin-bottom: 18px;
}

.Progress_Icon{
    padding: 5px;
    background: #434a50;
    display: flex;
    border-radius: 13px;
    width: 28px;
    height: 28px;
}

.Progress_Icon:hover {
    opacity: 0.7;
}

.Upload_Err_Icon{
    display: flex;
    border-radius: 13px;
    margin-left: 60px;
    position: relative;
    top: -2px;
}

/* .Upload_Err_Icon:hover {
    opacity: 0.7;
} */

.ProgressBar_progress .bg-success {
    background-color: #67aaff !important;;
}

.ProgressBar_success .bg-success {
    background-color: #82dfc6 !important;;
}

.ProgressBar_error .bg-success {
    background-color: #fe6e6e !important;;
}

.Progress_Title {
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    font-size: 0.875rem;
    color: white;
    text-overflow: ellipsis;
}

.Progress_Message {
    font-size: 0.875rem;
    text-align: right;
    color: #919597;
    white-space: nowrap;
}

.Progress_Total_Title {
    width: 270px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-bottom: 8px;;
    font-size: 1rem !important;
}

.Total_progress_container {
    width: 408px;
    padding: 18px 16px 12px;
    border-radius: 8px;
    height: 100px;
    margin: 32px 0 8px;
    background-color: #20252b;
    overflow-y: auto;
}

.ProgressBar_progress_total {
    width: 375px;
    height: 4px;
    margin: 5px 0;
}

.ProgressBar_progress_total .bg-success {
    background-color: #67aaff !important;
}
.Progress_Title_status {
    width: 324px;
    display: flex;
    justify-content: space-between;
}
.Progress_Title_number {
    display: flex;
    justify-content: space-between;
    font-size: 00.875rem !important;
}

.Progress_Title_progress {
    width: 150px;
    color: #919597;
    white-space: nowrap;
}

.Progress_Title_errors {
    color: #ff6e6e;
    width: 50px;
    margin: 0 0 0 0;
    white-space: nowrap;
    text-align: right;
    margin-left: auto;
}

.Progress_Title_cancels {
    color: #ff6e6e;
    width: 50px;
    margin: 0 8px 0 0;
    white-space: nowrap;
    text-align: right;
    margin-left: auto;
    margin-right: 14%;
}

.scrollbar {
    overflow-y: scroll;
}

.scrollbar-primary {
    scrollbar-color: #434a50 #f5f5f5;

}

.scrollbar-primary::-webkit-scrollbar {
    width: 7px;
    background-color: transparent;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #434a50;
}

.popup-content {
    color: white !important;
    background-color: #2f363d !important;
    outline: none !important;
    padding: 15px !important;
    font-size: 00.875rem !important;
    opacity: 1.0 !important;
}
