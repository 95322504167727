.App {
  background-image: url("./assets/background_Image.png");
  background-position-x: center;
  background-position-y: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  background-color: #20252b;
}

.Container {
  display: flex;
  align-items: center;
  padding: 62px 72px;
  justify-content: space-between;
  color: #fff;
}

.Container_Title {
  max-width: 356px;
}

.Spinner_wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  z-index: 9999999;
  opacity: 0.2;
}
.Container_logo {
  width: 32px;
  height: 40px;
}

.SelectForm {
  width: 552px;
  height: 676px;
  padding: 64px 72px;
  border-radius: 16px;
  background-color: #2f363d;
  box-sizing:border-box;
}

.SelectForm_header {
  font-size: 1.313rem ;
  font-weight: 600;
  line-height: 1.52;
  letter-spacing: 0.2px;
  margin-bottom: 32px;
}

.SelectForm_header_progress {
  font-size: 1.313rem ;
  font-weight: 600;
  line-height: 1.52;
  letter-spacing: 0.2px;
  margin-bottom: 15px;
}

.List_name_header {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.52;
  letter-spacing: 0.2px;
  margin-bottom: 32px;
  color: #919597;
  overflow: auto;
}

.WorkspaceAccess_error {
  color: #fee86c;
  margin-bottom: 30px;
}

.Workspace_Create_Modal .modal-content {
  color: #fff !important;
  background-color: #434a50 !important;
  border-color: #434a50 !important;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .3rem;
  outline: 0;
}

.Workspace_Create_Modal .close {
  color: #fff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Send_to_Bluescape {
  width: 100%;
  height: 60px;
  margin-top: 30px;
}

.canvas_color {
  width: 20px;
  height: 20px;
  border-radius: 12px;
  border: solid 1px rgba(255, 255, 255, 0.1);
}

.color_wrapper {
  display:  flex;
  justify-content: space-between;
  padding-right: 50px;
}

.Input_Title {
  color: #919597;
}

.canvas_color_blue {
  background: #99c6ff;
}

.canvas_color_turtle {
  background: #82dec6;
}

.canvas_color_cheese {
  background: #fef09f;
}

.canvas_color_tangerine {
  background: #ffc39e;
}

.canvas_color_sherbet {
  background: #ffa1a1;
}

.canvas_color_peony {
  background: #ff9ee6;
}

.canvas_color_grape  {
  background: #ddadff;
}

.option-input {
  appearance: none;
  position: relative;
  top: 13.33333px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 20px;
  width: 20px;
  transition: all 0.15s ease-out 0s;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  position: relative;
}

.option-input:hover {
  opacity: 0.9;
}

.option-input:checked {
  box-shadow: 0 0 0pt 8pt #575e62;
}

.option-input.radio {
  border-radius: 50%;
}

.option-input.radio::after {
  border-radius: 50%;
}

.canvasInput {
  width: 408px;
  text-align: start;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff !important;
  background-color: #434a50 !important;
  border-color: #434a50 !important;
  box-shadow: 0 0 0 0.2rem rgb(67 74 80 / 50%) !important;
  margin-bottom: 30px;
}
.canvasInput::placeholder {
  color: #919597;
  opacity: 1; /* Firefox */
}
